<template>
    <div>
        <errors-bag-list class='d-print-none'></errors-bag-list>

        <div class='d-flex justify-content-center mb-3' v-if='user'>
            <div id='user-badge' class='text-center'>
                <h3>{{ fullname }}</h3>

                <div class='separator'>
                    <h4><b>Роли: </b><br/>{{ roles }}</h4>
                </div>

                <div class='separator'>
                    <img :src='barcode' alt='ШК входа' id='barcode' />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import join from 'lodash/join';

    import ErrorsBagList from '../_common/ErrorsBagList';

    import ErrorsBag from '../../utils/errorsBag';
    import Barcoder from '../../utils/barcode-transition/Barcoder';

    import { USERS_ENDPOINT } from '../../utils/endpoints';

    const DEFAULT_ROLES = 'отсутствуют';
    const DEFAULT_FULLNAME = 'Имя не указано';

    export default {
        name: "UsersBarcode",
        props: [ 'id' ],
        components: { ErrorsBagList },
        computed: {
            roles() {
                if ( this.user.roles.length <= 0 ) {
                    return DEFAULT_ROLES;
                }

                return join( this.user.roles, ', ' ).replace( /ROLE_/gi, '' ).toLowerCase();
            },

            fullname() {
                return this.user.fullname ? this.user.fullname : DEFAULT_FULLNAME;
            }
        },
        data() {
            return {
                ErrorsBag,
                user: null,
                barcode: null
            };
        },
        methods: {
            fetchUser() {
                let params = { appends: [ 'storages' ] };

                this.$http
                    .get( USERS_ENDPOINT + '/' + this.id, { params } )
                    .then(
                        response => {
                            this.user = response.data.data;

                            if ( this.user.login_barcode ) {
                                Barcoder
                                    .get( [ this.user.login_barcode ] )
                                    .then( response => this.barcode = response.data[ 0 ].content );
                            }
                        },
                        response => ErrorsBag.fill( response.body )
                    );
            }
        },
        created() {
            this.fetchUser();
        }
    }
</script>

<style scoped lang='sass'>
    #user-badge
        width: 400px
        padding: 10px
        border: 1px solid #000

    #barcode
        width: 380px
        height: 100px

    .separator
        margin-top: 20px
</style>
